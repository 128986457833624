<template>
	<b-modal id="changePassword-modal" hide-footer hide-header hide-header-close size="sm" >
 
	   	<div class="logout-content">

	   		<div class="text-center text-dark m-3">
				<h4>Change Password</h4>
				<p v-if="errorShow" style="color:red;">{{ errorMsg }}</p>
			</div>
 
			 
			 <div class="label m-3 text-dark">
		   			
					<label>Enter current password</label>
					<input class="form-control" v-model="currentPw"/>
					<label>Enter new password</label>
					<input class="form-control" v-model="newPw1"/>
					<label>Confirm new password</label>
					<input class="form-control" v-model="newPw2"/>
		   		</div>

		   		<div class="width-100">
		   			
					<div class="input-group">
						 <button class="btn btn-main" v-on:click="changePassword"> Confirm </button>
                         <button class="btn text-dark btn-second" v-on:click="cancel"> Cancel </button>
					</div>
		   		</div>
	   		
	   	</div>

	   	

	  </b-modal>
</template>

<script>
export default {
  name: 'ChangePassword',
  data: function() {
        return  { 
			newPw1:'',
			newPw2:'',
			currentPw:'',
			userId:'',
			errorMsg:'',
			errorShow: false
        }
    },
  	computed: {
    	init() {
        	return this.$store.state.init;
    	},
    	show() {
	        return this.$store.state.show;
	    },
   	},
   	methods: {
   		cancel: function(){
   		 	this.$bvModal.hide('changePassword-modal'); 
   		 },

   		changePassword: function(){
				this.userId = localStorage.getItem("userId")
			    let userId = localStorage.getItem("userId")

			const params = {
				currentPw: this.currentPw,
				newPw1: this.newPw1,
				newPw2: this.newPw2,
				uid: userId,

          }
          const data = Object.keys(params)
                  .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                  .join('&');

          const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: '/ax/submitPwChange',
					data
                    };

				this.axios(options)
				.then((response) => { 
          		console.log(response.data)
					
           			}).catch((err) => { 
                          
                            this.errorShow = true
                            this.errorMsg = err.response.data.msgText
                            setTimeout(()=>{
                              window.location.reload();
                         
                          }, 3000)
                       

                     })
			}

   	},

}
</script>
 
<style scoped lang="scss">
	.text-dark{
		color: black !important;
	}
	#logout-modal {
		.logout-content {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
		}
	}
	.btn-main{
	  background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;
		background-size:0% 100%;
		background-repeat:no-repeat;
		transition:.4s;
		width:100%;
		border-radius: 10px;
		border: 1px #007bff solid !important;
		color: #007bff !important;
  }
  .btn-main:hover{
	  background-size:100% 100%;
    	color:white !important;
  }
  .btn-second{
	  	background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;
		background-size:0% 100%;
		background-repeat:no-repeat;
		transition:.4s;
		width:100%;
		border-radius: 10px;
		padding:5px;
		border: 1px #007bff solid;
		color: white !important;
  }
   .btn-second:hover{
	  background-size:100% 100%;
    	color:white !important;
  }
  .btn-second {
    color: #007bff !important;
  }
	.label{
		color: black;
	}
</style>
