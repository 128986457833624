<template>
  <div class="backdrop">
    <div class="b-modal">
        <div class="modal-header text-right">
            <h5 class="m-2 text-white">Upload in CSV</h5>
            <p @click="closeModal" class="m-2 x">x</p>
        </div>
      <div class="modalBody">
         <form  v-on:submit.prevent="uploadFile($event)">
                <b-form-file
                    v-model="upload"
                    :state="Boolean(upload)"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
            ></b-form-file>
            <button  type="submit" class="btn btn-main" id="saveBtn">Submit</button>
          </form>
          

          <div v-if="showMsg">
              <button class="btn btn-failed">number of Failed Upload: {{msgFailed}}</button>
               <button class="btn btn-success">number of Successful Upload: {{msgSuccess}}</button>
              <ul v-for="details in detailedMsg" :key="details.id">Details: 
                  <p>
                      {{details}}
                  </p>
                  <table class="table">
                    <thead>
                        <tr >
                            <th>Errors</th>
                            <th>Line Number</th>
                            <th>Title</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Mobile Phone</th>
                            <th>Home Phone</th>
                            <th>Email Address</th>
                            <th >Signup Host </th>
                             <th>Birthyear</th>
                             <th>birthMm</th>
                             <th>birthDd</th>
                             <th></th>
                             <th></th>
                             <th></th>

                           
                           
                        </tr>
                        </thead>
                      
                        <tbody v-for="item in details" :key="item.id">
                             <td >{{item.entityId}}</td>
                             <td>{{item.emailAddress}}</td>
                             <td>{{item.firstName}}</td>
                            <td>{{item.lastName}}</td>
                            <td>{{item.mobilePhone}}</td>
                            <td>{{item.signupDate}}</td>
                            <td>{{item.activationDate}}</td>
                            <td>{{item.status}}</td>
                             <td><button class="btn btn-cancel text-dark" @click="gotoCustomer(item.entityId)">view</button></td>
                            
                        </tbody>
                    </table>
              </ul>
              <button @click="closeModal" class="btn btn-main">Close</button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    components: {

    },
    data: function() {
        return {
            upload: null,
            csv: 'imageFile',
            isSuccess: false,
            isFailed: false,
            response:'',
            errors: {
                profile: {
                    show:false,
                    msg: ""
                },
            },
            detailedMsg:[],
            showMsg: false,
            showSuccessMsg: false,
            msgSuccess: "",
            msgfailed:""


            
        }
    },

    computed: {
     
    },

    mounted() {
     
    },

    watch: {
      
    },

    methods: {
        closeModal(){
            this.$emit("close")
        },

        done(){
            this.$emit("close")
            this.$emit("done")
        },
     
       uploadFile(event) {
            const params = {
                customersFile: this.upload,
               
                }


                let data = new FormData();
                  
                    data.append('customersFile', this.upload);
                  

            
            const options = {
                method: 'POST',
                headers: {
                    'content-type': 'image/png',
                    'vmiadmin-authz': localStorage.getItem("tokenId") 
                },
                data,
                url: '/ax/createBulkCustomers',
            };

           this.axios(options).then(
                response => {
                    console.log(response.data.failedDataList)
                    this.response = response.request.status

                    if(this.response = 200){
                         this.showMsg = true
                        this.msgSuccess = response.data.numOfSuccess
                        this.detailedMsg = response.data.successList
                        this.msgFailed = response.data.numOfFailed
                        this.detailedMsg = response.data.failedDataList
                        // setTimeout( () => {
                        //     window.location.reload();
                        // },3000)

                    } else {
                        this.isFailed = true
                        this.msgFailed = response.data.numOfFailed
                        this.detailedMsg = response.data.failedDataList
                        setTimeout( () => {
                            window.location.reload();
                        },3000)

                    }
                    


                }
            )
        }
    }
}
</script>

<style scoped>
.backdrop {
    top: 0;
    right:0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 98;
    background: rgba(0, 0, 0, 0.6);

}
.b-modal {
    width: 700px;
    height: auto;
    margin: 175px auto;
    background: white;
    border-radius: 20px;
    border-color:white;
    border-width:2px;
    z-index: 99;
    overflow-x: hidden !important;
    color:black;
    max-height: calc(100vh - 110px);
      overflow-y: auto;

}

.modal-header {
    background-color: white;
    margin-right:0px;
    font-size:25px;
    display:flex;
    justify-content:space-between;
}

.modal-title{
    margin: 0 auto;
    color:white;
}

.modalBody {
    background-color:white;
    width:100%;
    padding:50px;
    border-radius: 10px;;
}

.buttonWrapper {
    width:100%;
}

.x {
    cursor:pointer;
}
.form-control{
  border-radius: 10px;;
}
.submit {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background-color:rgb(82, 35, 201);
    color:white;
    font-family:'PoppinsMedium', sans-serif;
    width:100%;
    margin-right:20px;
}

.ok {
    background-color:rgb(82, 35, 201);
    color:white;
    font-family:'PoppinsMedium', sans-serif;
    width:175px;
    margin-right:20px;
}

.cancel {
    color:#191919;
    background-color:lightgray;
    font-family:'PoppinsMedium', sans-serif;
    width:100%;

}
.btn-success{
    background-color: rgba(234, 183, 183, 0.75);
    cursor: none !important;
}
.btn-failed{
    background-color: rgba(183, 234, 183, 0.75);
    cursor: none !important;
}
.iconImg {
    width:50%;
}
 .form-group{
        width: 100%;
    }

    .btn-main{
        margin: 30px auto;
        border: 1px solid #481a69;
        width: 100%;
    }
    .btn-main:hover{
        background-color:#481a69;
        color: white;
    }


    @media screen and (max-width: 1100px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
     .heading {
         font-size:25px;
      }

      .subheading {
        font-size:12px;
      }
          
    }
   @media screen and (max-width: 800px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
      .heading {
         font-size:25px;
      }

    .subheading {
      font-size:12px;
    }
    .card {
      width:15rem;
      height:21rem;
      font-size:10px;
    }
        
  }

    @media screen and (max-width: 600px) {
          .firstThree {
             margin: 0 auto;
             display: flex;
             justify-content: center;
        }
         .card {
            width:12rem;
            height:16rem;
            padding-bottom: 10px;
          }
          .card h6{
            font-size: 10px !important;
          }
        
    }

    @media screen and (max-width: 420px) {

       .b-modal {
            width: 400px;
            height: auto;
            margin-top: 50px;
            background: white;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
        }

        .modalBody {
            background-color:white;
            width:100%;
            padding: 20px 0px;
            border-radius: 10px;;
        }
        .submit {
            background-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:60px;
        }

        .cancel {
            color:#191919;
            background-color:lightgray;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:60px;
        }

        .textField {
            width:100px;
            padding:5px;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
        }

        .checkboxGroup {
            width:300px;
            margin:0;
        }

        .sendCode {
            font-size:12px;
            margin-top: 5px;
            text-align:right;
            width:250px;
            font-weight:bold;
            cursor:pointer;
        }

        #pin1, #pin2, #pin3, #pin4, #pin5, #pin6 {
            width:40px;
            height:80px;
            padding:0;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
            font-size:30px;
            margin:2px;
            display: flex !important;
        }

        #mobile1, #mobile2, #mobile3, #mobile4, #mobile5, #mobile6, #mobile7, #mobile8, #mobile9, #mobile10 {
            width:20px;
            padding:5px;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
            margin:1px;
        }

        .otpLabel {
            font-size:15px;
            width:300px;
            padding:10px;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:20px;
        }

        .buttonWrapper {
            display: block;
            justify-content: center;
            width:100%;
        }

    }

    @media screen and (max-width: 380px) {
        .b-modal {
                width: 315px;
        }

        #pin1, #pin2, #pin3, #pin4, #pin5, #pin6 {
            width:33px;
        }

        #areaCode h3{
            font-size:1px !important;
        }

        .mobileWrapper {
            display: flex;
            justify-content: flex-start;
            width:100%;
        }

        .formWrapper {
            display: flex;
            justify-content: flex-start;
            width:100%;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
        }

        .buttonWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:0px;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:0px;
        }

        .msgCon {
            width:300px;
        }

        .submit {
            background-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:20px;
        }

        .cancel {
            color:#191919;
            background-color:lightgray;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:20px;
        }
        
    }
</style>